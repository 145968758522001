<template>
    <div data-app>
      
      <div class="card card-custom h-100">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Edit Deplesi
            </h3>
          </div>
        </div>
  
        <!--begin::Form-->
          <div class="card-body">
          <perfect-scrollbar
          class="scroll"
          style="max-height: 98%; position: relative;"
          >
            <ValidationObserver ref="formAdd">
            <b-form @submit="onSubmit" v-if="show">

                <validationProvider :name="`Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors, valid }">
                <b-form-group 
                :id="`input-group-edit-4`" 
                label="Jumlah:" 
                :label-for="`input-edit-4`"
                :invalid-feedback="errors[0]"
                :state="valid">
                    <vue-number-input 
                    :attrs="{ id: `input-edit-4`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                    v-model="form.jumlah"
                    :min="0"
                    :max="9999999999"
                    required
                    center
                    controls
                    ></vue-number-input>
                </b-form-group>
                </validationProvider>
  
            </b-form>
            </ValidationObserver>
  
            <div v-if="sdata.action == 'edit'" class="mt-3">
                <button 
                  @click="onSubmit($event, 'ajukan')" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Simpan
                </button>
                &nbsp;
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>

            <div v-if="sdata.action == 'perbaikan'" class="mt-3">
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'draft')" 
                  ref="kt_submit"
                  class="btn btn-info font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Simpan Draf
                </button>
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'ajukan')" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Ajukan
                </button>
                <button
                  v-if="buttonMenu == 'ajukan'" 
                  @click="onBackDraf" 
                  ref="kt_submit_draf"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Kembalikan Draf
                </button>
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
          </perfect-scrollbar>  
          </div>
        <!--end::Form-->
      </div>
    </div>
  </template>
  
  <script>
  import PopulasiService from "@/core/services/api/nasional/populasi.service";
  import ErrorService from "@/core/services/error.service";
  
  export default {
    name: "unggas-nasional-data-populasi-deplesi-edit",
    props: {
        sdata: {
            required: true
        }
    },
    data() {
      return {
        loading: false,
        form: {
          _pid: this.sdata._idp,
          _id: this.sdata._idc,
          deplesi: this.sdata._id,
          jumlah: null,
          status: 15,
          type: 'ajukan',
        },
        buttonMenu: 'draf',
        show: true,
      }
    },
    methods: {
      closeDialog() {
          return this.$emit('close-modal', {status: true, refresh: false})
      },
      removeSpinner(button) { 
        button.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      },
      getData () {
        const params = {
          _pid: this.sdata._idp,
          _cid: this.sdata._idc,
          deplesi: this.sdata._id,
        }

        let getData
        if(this.sdata.action == 'edit') {
          getData = PopulasiService.getDeplesiEdit(params)
        }
        if(this.sdata.action == 'perbaikan') {
          getData = PopulasiService.getDeplesiPerbaikan(params)
        }
        getData.then((res) => {
          if (res.data.status) {
            this.form._pid = res.data.data._idp
            this.form._id = res.data.data._idc
            this.form.deplesi = res.data.data._id
            if(this.sdata.action == 'perbaikan') {
              this.form.deplesi_ajuan = res.data.data._deplesi
              this.form.status = res.data.data._ids
              if(this.form.status == 170) {
                this.buttonMenu = 'ajukan'
              }
            }
            this.form.jumlah = res.data.data.jumlah
            return;
          }
          ErrorService.message(res.data)
          this.closeDialog()
        })
        .catch((err) => {
          ErrorService.status(err)
          this.closeDialog()
        })
      },
      onSubmit(evt, ajukan) {
        evt.preventDefault()
        this.$refs.formAdd.validate().then(success => {
          if (!success) {
              ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan lengkapi form data terlebih dahulu',
              })
              return;
          }
  
          // set spinner to submit button
          const submitButton = this.$refs["kt_submit"];
          submitButton.classList.add("spinner", "spinner-light", "spinner-right");
  
          // dummy delay
          // setTimeout(() => {
            let setData
              if(this.sdata.action == 'edit') {
                setData = PopulasiService.updateDeplesi(this.form)
              }
              if(this.sdata.action == 'perbaikan') {
                if(ajukan == 'draf') {
                  this.form.status = 15
                  this.form.type = 'ajukan'
                }
                if(ajukan == 'ajukan') {
                  this.form.status = 170
                  this.form.type = 'ajukan'
                }
                setData = PopulasiService.updateDeplesiPerbaikan(this.form)
              }
              setData.then((res) => {
                  this.removeSpinner(submitButton)
                  ErrorService.message(res.data)
                  
                  if (res.data.status) {
                    this.$emit('close-modal', {status: true, refresh: true})
                  }
                  return;
              })
              .catch((err) => {
                this.removeSpinner(submitButton)
                ErrorService.status(err)
              })
  
          // }, 1000);
        })
      },
      onBackDraf (evt) {
        evt.preventDefault()
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit_draf"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            PopulasiService.updateDeplesiPerbaikan({...this.form, status: 15, type: 'draf'})
            .then((res) => {
                if (res.data.status) { 
                  this.form.status = 15
                  this.form.type = 'draf'   
                  this.buttonMenu = 'draf'   
                }
                ErrorService.message(res.data)
                this.removeSpinner(submitButton)
            })
            .catch((err) => {
                this.removeSpinner(submitButton)
                return ErrorService.status(err)
            })
        },
    },
    mounted() {
      this.getData();
    },
  };
  </script>